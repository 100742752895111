import {
	type DefaultBackendMessageStages,
	type PlacementMessageStagesConfig,
} from '../../../../create-placement/types';
import { applyHydrate } from '../hydrate';
import { applyTransformMetadata } from '../transform-metadata';

export const defaultBackendPlacementStages: PlacementMessageStagesConfig<
	DefaultBackendMessageStages
> = ({ transformMetadata, hydrate }) => ({
	init: applyTransformMetadata(transformMetadata),
	postInit: applyHydrate(hydrate),
});
