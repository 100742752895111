import { type AnyBackendMessageBody, type AnyPlacementMessageRendererProps } from '../../types';

export const flattenMessageForFrontend = ({
	context,
	...message
}: AnyBackendMessageBody): AnyPlacementMessageRendererProps => ({ ...message, ...context });

/**
 * By convention we flatten the response on the backend so we aren't so reliant on memoizing props on the frontend.
 */
export const flattenMessagesForFrontend = (input: {
	messages: Array<AnyBackendMessageBody>;
}): { messages: Array<AnyPlacementMessageRendererProps> } => ({
	messages: input.messages.map(flattenMessageForFrontend),
});
