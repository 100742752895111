import {
	type AnyPipelineMessageStageProcessor,
	type AnyPipelineMessageStageProcessorAsyncExecute,
	type PipelineMessage,
} from '@post-office/shared-contracts';

import { type RuntimeParams } from '../../create-placement-component/types';
import { PlacementSystemError } from '../../error';
import {
	type AnyBackendMessageBody,
	type AnyBackendPlacementStage,
	type PlacementBackendRuntime,
	type PlacementStages,
	type RequestContext,
} from '../../types';
import { createPlacementStages } from '../../util/runtime';

export const productionRuntime: PlacementBackendRuntime = (config) => {
	const placementStages = createPlacementStages(config);

	const stageProcessors = placementStagesToStageProcessors(placementStages);

	return ({ koa, request }) => {
		const stages = stageProcessors({ request });

		return async (params: RuntimeParams) => {
			const messages = await koa.ctx.messageSelection.getEligibleMessages(request, {
				...params,
				stages,
			});

			return {
				data: { messages },
				// Add error output when compatible with orchestratio
				errors: [],
			};
		};
	};
};

const placementStagesToStageProcessors =
	(placementStages: PlacementStages) =>
	(contexts: {
		request: RequestContext;
	}): { [stageName in keyof PlacementStages]: AnyPipelineMessageStageProcessor } => {
		const stageProcessors: {
			[stageName in keyof PlacementStages]: AnyPipelineMessageStageProcessor;
		} = {};

		Object.entries(placementStages).forEach((stage) => {
			const [stageName] = stage;

			stageProcessors[stageName as keyof PlacementStages] =
				placementStageToStageProcessor(contexts)(stage);
		});

		return stageProcessors;
	};

const placementStageToStageProcessor =
	(contexts: { request: RequestContext }) =>
	([stageName, placementStage]: [
		stageName: string,
		placementStage: AnyBackendPlacementStage | undefined,
	]): AnyPipelineMessageStageProcessor => {
		const execute = placementStageToStageProcessorExecute(placementStage)(contexts);

		return () => ({
			id: stageName,
			execute,
		});
	};

const placementStageToStageProcessorExecute =
	(placementStage: AnyBackendPlacementStage = identityPlacementStage) =>
	({ request }: { request: RequestContext }): AnyPipelineMessageStageProcessorAsyncExecute =>
	async (pipelineMessages) => {
		const placementMessages = pipelineMessages.map(stripUnserializablePayload);

		const pipelineMessagesByMessageInstanceId: Record<string, PipelineMessage> = Object.fromEntries(
			pipelineMessages.map((pipelineMessage) => [
				pipelineMessage.messageInstanceId,
				pipelineMessage,
			]),
		);

		validateSerializablePayload(placementMessages);

		const processedData = await placementStage({
			data: { messages: placementMessages },
			request,
		});

		const combinedMessages = processedData.messages.map(
			(processedMessage) =>
				({
					...pipelineMessagesByMessageInstanceId[processedMessage.messageInstanceId],
					...processedMessage,
				}) as PipelineMessage,
		);

		return combinedMessages;
	};

const stripUnserializablePayload = (pipelineMessage: PipelineMessage): AnyBackendMessageBody => ({
	messageInstanceId: pipelineMessage.messageInstanceId,
	messageTemplateId: pipelineMessage.messageTemplateId,
	messageCategory: pipelineMessage.messageCategory,
	createdAt: pipelineMessage.createdAt,
	eventTime: pipelineMessage.eventTime,
	context: pipelineMessage.context,
	triggerId: pipelineMessage.triggerId,
	messageCreationType: pipelineMessage.messageCreationType,
	recommendationSession: pipelineMessage.recommendationSession,
	readStatus: pipelineMessage.readStatus,
});

const validateSerializablePayload = (payload: unknown): void => {
	try {
		JSON.stringify(payload);
	} catch {
		throw new PlacementSystemError({
			message:
				'failed to backend message body, this may mean a non-serializable field has to been added and should be removed',
		});
	}
};

const identityPlacementStage: AnyBackendPlacementStage = async (input) => input.data;
