import {
	type AnyArityOneAsyncFunction,
	type AnyArityOneFunction,
} from '@post-office/shared-contracts/pipeline/contraints';

import { PlacementMessageError } from '../../error';
import {
	type AnyBackendMessageBody,
	type AnyBackendMessageStage,
	type AnyBackendPlacementStage,
	type AnyBackendPlacementStageInput,
	type AnyMessagesStages,
	type AnyPlacementMessageStages,
	type PlacementBackendRuntimeConfig,
	type PlacementBackendRuntimeErrorResult,
	type PlacementBackendRuntimeResult,
} from '../../types';

export const createPlacementStages = (config: PlacementBackendRuntimeConfig) => {
	const placementMessagesStages = messagesStagesToPlacementMessageStages(config.messagesStages);

	return config.placementStages(placementMessagesStages);
};

export const messagesStagesToPlacementMessageStages = (
	messagesStages: AnyMessagesStages,
): AnyPlacementMessageStages => {
	const messagesStagesByStageName = messagesStagesToMessagesStagesByStageName(messagesStages);

	const placementMessageStages: AnyPlacementMessageStages = {};

	Object.entries(messagesStagesByStageName).forEach(([stageName, stage]) => {
		const messageStage = createMessageStageRouter(stage);

		if (placementMessageStages[stageName]) {
			throw new PlacementMessageError({
				message: 'a duplicate stage name was found. Stage names should be unique',
			});
		}

		const placementMessageStage = messageStageToPlacementMessageStage(messageStage);

		nameFunction(stageName, placementMessageStage);

		placementMessageStages[stageName] = placementMessageStage;
	});

	return placementMessageStages;
};

export const messageStageToPlacementMessageStage =
	(messageStage: AnyBackendMessageStage): AnyBackendPlacementStage =>
	async ({ data, request }) => {
		const result = await messageStageToRuntimeStage(messageStage)({ data, request });

		const inputCount = data.messages.length;
		const successCount = result.data.messages.length;

		if (result.errors.length) {
			const uniqueErroredMessageTemplateIds = Array.from(
				new Set(result.errors.map((error) => error.messageTemplateId)).values(),
			);

			result.errors.forEach((error) =>
				request.logger.error(
					{
						messageTemplateId: error.messageTemplateId,
						messageInstanceId: error.messageInstanceId,
						reason: error.reason,
					},
					'error running message stage in placement',
				),
			);

			request.logger.error(
				{
					errors: result.errors,
					messageTemplateIds: uniqueErroredMessageTemplateIds,
					inputCount,
					successCount,
				},
				'errors running some message in placement',
			);
		}

		return result.data;
	};

type AnyMessagesStagesByStageName = {
	[stageName: string]: {
		[messageTemplateId: string]: AnyBackendMessageStage;
	};
};

export const messagesStagesToMessagesStagesByStageName = (
	messagesStages: AnyMessagesStages,
): AnyMessagesStagesByStageName => {
	const messagesStagesByStageName: AnyMessagesStagesByStageName = {};

	Object.entries(messagesStages).forEach(([messageTemplateId, messageStages]) =>
		Object.entries(messageStages).forEach(([stageName, messageStage]) => {
			if (!messagesStagesByStageName?.[stageName]) {
				messagesStagesByStageName[stageName] = {};
			}

			const asAsyncMessageStage: AnyBackendMessageStage = async (params) => messageStage(params);

			// We can use non null assertion as we initilize above
			messagesStagesByStageName[stageName]![messageTemplateId] = asAsyncMessageStage;
		}),
	);

	return messagesStagesByStageName;
};

// This should return a message context
const identityMessageStage: AnyBackendMessageStage = async ({ message }) => message;

export const createMessageStageRouter =
	(stage: { [messageTemplateId: string]: AnyBackendMessageStage }): AnyBackendMessageStage =>
	({ request, message }) => {
		const stageProcessor = stage?.[message.messageTemplateId] ?? identityMessageStage;

		return stageProcessor({ request, message });
	};

export const messageStageToRuntimeStage =
	(messageStage: AnyBackendMessageStage) =>
	async ({
		data,
		request,
	}: AnyBackendPlacementStageInput): Promise<PlacementBackendRuntimeResult> => {
		const processMessage = async (message: AnyBackendMessageBody) =>
			messageStage({ request, message });

		const promises = data.messages.map(processMessage);

		const results = await Promise.allSettled(promises);

		const messages = results
			.filter((settledResult): settledResult is PromiseFulfilledResult<AnyBackendMessageBody> => {
				return settledResult.status === 'fulfilled';
			})
			.map((e) => e.value);

		const errors: Array<PlacementBackendRuntimeErrorResult> = results
			.map((settledResult, index) => {
				if (settledResult.status === 'rejected') {
					const originalMessage = data.messages?.[index];

					const messageInstanceId = originalMessage?.messageInstanceId;
					const messageTemplateId = originalMessage?.messageTemplateId;

					return {
						messageInstanceId,
						messageTemplateId,
						...settledResult,
					};
				}

				return settledResult;
			})
			.filter((e) => e.status === 'rejected')
			.map(({ status: _status, ...rest }) => rest as unknown as PlacementBackendRuntimeErrorResult);

		return {
			data: {
				messages,
			},
			errors,
		};
	};

const nameFunction = <T extends AnyArityOneFunction | AnyArityOneAsyncFunction>(
	name: string,
	fn: T,
): T => Object.defineProperty(fn, 'name', { value: name });
