// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { type VMessageTemplateContext } from '../../index.ts';

import { AssertError } from '@post-office/validation-types';
export function assert(value: any): VMessageTemplateContext {
    const result = validate(value);
    if (!result.success) {
        const firstError = result.errors[0];
        throw new AssertError('assert', firstError.path, firstError.expected, firstError.value);
    }
    return result.data as VMessageTemplateContext;
}
export { assert as assertMessageTemplateContext };


export function is(value: any): value is VMessageTemplateContext {
	const result = validate(value);
    return result.success;
}
export { is as isMessageTemplateContext };


import { type ValidationResult } from '@post-office/validation-types';
export function validate(value: any): ValidationResult<VMessageTemplateContext> {
    const errors = [];
    const originalData = value;
    
	if (typeof value !== 'object' || value === null || Array.isArray(value)) {
        errors.push({ path: 'value', expected: 'MessageTemplateContext', value: value });
    } else {
			if (!('nextBestActionType' in value)) {
				errors.push({ path: 'value["nextBestActionType"]', expected: '"CREATE_SPACE" | "CREATE_PAGE" | "CREATE_WHITEBOARD"', value: undefined });
			} else {
				
    {
        let value__nextBestActionType___valid = false;
        
        if (!value__nextBestActionType___valid) {
            const value__nextBestActionType___errors_0 = [];
            if (value["nextBestActionType"] !== "CREATE_SPACE") value__nextBestActionType___errors_0.push({ path: 'value["nextBestActionType"]', expected: "CREATE_SPACE", value: value["nextBestActionType"] });
            if (value__nextBestActionType___errors_0.length === 0) {
                value__nextBestActionType___valid = true;
            }
        }

        if (!value__nextBestActionType___valid) {
            const value__nextBestActionType___errors_1 = [];
            if (value["nextBestActionType"] !== "CREATE_PAGE") value__nextBestActionType___errors_1.push({ path: 'value["nextBestActionType"]', expected: "CREATE_PAGE", value: value["nextBestActionType"] });
            if (value__nextBestActionType___errors_1.length === 0) {
                value__nextBestActionType___valid = true;
            }
        }

        if (!value__nextBestActionType___valid) {
            const value__nextBestActionType___errors_2 = [];
            if (value["nextBestActionType"] !== "CREATE_WHITEBOARD") value__nextBestActionType___errors_2.push({ path: 'value["nextBestActionType"]', expected: "CREATE_WHITEBOARD", value: value["nextBestActionType"] });
            if (value__nextBestActionType___errors_2.length === 0) {
                value__nextBestActionType___valid = true;
            }
        }
        if (!value__nextBestActionType___valid) {
            errors.push({ path: 'value["nextBestActionType"]', expected: '"CREATE_SPACE" | "CREATE_PAGE" | "CREATE_WHITEBOARD"', value: value["nextBestActionType"] });
        }
    }
			}
    }
    if (errors.length === 0) return { success: true, data: value };
    return { success: false, data: originalData, errors: errors };
}
export { validate as validateMessageTemplateContext };
