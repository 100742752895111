import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import { Box, xcss } from '@atlaskit/primitives';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from 'react-intl-next';

import { portalZIndexFix } from '../utils/portalZIndexFix';

const POST_OFFICE_AD_CONTROLS_FLAG_WRAPPER = 'post-office-ad-controls-flag-wrapper';

/**-Title--**/

const titleContainerStyles = xcss({
	font: token(
		'font.heading.xsmall',
		'normal 600 14px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
	),
});

const Title = () => (
	<Box xcss={titleContainerStyles}>
		<FormattedMessage
			id="post-office.ad-controls.dismiss-flag.not-interested.title"
			defaultMessage="Got it, thanks for letting us know"
			description="The title for a flag which is displayed when the Not Interested button is pressed. https://hello.atlassian.net/l/cp/ssoqm2Fq"
		/>
	</Box>
);

/**--Description--**/

const Description = () => (
	<FormattedMessage
		id="post-office.ad-controls.dismiss-flag.not-interested.subtitle"
		defaultMessage="You won’t see this here as often."
		description="The subtitle for a flag which is displayed when the Not Interested button is pressed. https://hello.atlassian.net/l/cp/ssoqm2Fq"
	/>
);

/**--Confirm Icon--*/

const ConfirmIcon = () => <Tick label="Success" primaryColor={token('color.icon.success', G300)} />;

/**-Confirm Dismiss Flag--*/

export type AdControlsFlagProps = {
	messageInstanceId: string;
	onCloseFlag: () => void;
};

export const AdControlsFlag = ({ messageInstanceId, onCloseFlag }: AdControlsFlagProps) => {
	portalZIndexFix(POST_OFFICE_AD_CONTROLS_FLAG_WRAPPER);
	return (
		<FlagGroup>
			<div id={POST_OFFICE_AD_CONTROLS_FLAG_WRAPPER}>
				<AutoDismissFlag
					icon={<ConfirmIcon />}
					title={<Title />}
					description={<Description />}
					id={messageInstanceId}
					key={messageInstanceId}
					onDismissed={() => onCloseFlag()}
				/>
			</div>
		</FlagGroup>
	);
};
