import { Pressable, type XCSS } from '@atlaskit/primitives';
import { type ReactNode } from 'react';

export type BaseButtonProps = {
	ctaText: ReactNode;
	onCTAClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export type PressableProps = BaseButtonProps & {
	pressableStyle: XCSS;
};

export const NextBestActionPressable = ({
	ctaText,
	onCTAClick,
	pressableStyle,
}: PressableProps) => {
	return (
		<Pressable onClick={onCTAClick} xcss={pressableStyle}>
			{ctaText}
		</Pressable>
	);
};
