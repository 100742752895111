import Button from '@atlaskit/button';
import Link from '@atlaskit/link';
import AkModal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { portalZIndexFix } from '../utils/portalZIndexFix';

const WHY_AM_I_SEEING_THIS_MODAL_CONTENT = 'why-am-i-seeing-this-modal-content';

/*--Modal--*/

type ModalProps = {
	children: React.ReactNode;
	isOpen: boolean;
	onClose?: () => void;
	modalOpenCallback?: () => void;
};

const REASONS = [
	<FormattedMessage
		id="post-office.ad-controls.default-reason.team-size"
		description="the first reason for displaying the message to the customer: the size of the customer's team"
		defaultMessage="the size of your team"
	/>,
	<FormattedMessage
		id="post-office.ad-controls.default-reason.existing-products"
		description="the second reason for displaying the message to the customer: the customer's existing Atlassian products"
		defaultMessage="your existing Atlassian products"
	/>,
];

export const Modal = ({ children, isOpen, onClose }: ModalProps) => {
	portalZIndexFix(WHY_AM_I_SEEING_THIS_MODAL_CONTENT);

	return (
		<ModalTransition>
			{isOpen && (
				<AkModal shouldCloseOnEscapePress={true} onClose={onClose}>
					<div id={WHY_AM_I_SEEING_THIS_MODAL_CONTENT}>{children}</div>
				</AkModal>
			)}
		</ModalTransition>
	);
};

/*--Confirm Button--*/

type ConfirmButtonProps = {
	onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => unknown;
};

const AcceptButton = ({ onClick }: ConfirmButtonProps) => (
	<Button appearance="primary" onClick={onClick}>
		<FormattedMessage
			id="post-office.ad-controls.reasons-modal.accept-button.label-ok"
			defaultMessage="OK"
			description="The text on the button that dismisses a modal which provides reasons a message has been displayed"
		/>
	</Button>
);

/*--Title--*/

const titleContainerStyles = xcss({
	font: token(
		'font.heading.medium',
		'normal 500 20px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
	),
});

const Title = () => (
	<Box xcss={titleContainerStyles}>
		<FormattedMessage
			id="post-office.ad-controls.reasons-modal.title"
			defaultMessage="Why am I seeing this?"
			description="The title for a modal that displays the reasons a message was displayed."
		/>
	</Box>
);

/*--Body--*/
const DefaultBody = () => (
	<p>
		<FormattedMessage
			id="post-office.ad-controls.reasons-modal.body"
			defaultMessage="We think you'd find this relevant. This is based on:"
			description="Explanation of why we think the message will be releavant to the customer."
		/>
	</p>
);

/*--Reasons List--*/

type ReasonsListProps = { reasons: JSX.Element[] };

const reasonsListStyles = css({
	listStyleType: 'disc',
	paddingLeft: token('space.250', '20px'),
	marginTop: token('space.150', '12px'),
});

const ReasonsList = ({ reasons }: ReasonsListProps) => (
	<ul css={reasonsListStyles}>
		{reasons.map((reason, index) => (
			<li key={index}>{reason}</li>
		))}
	</ul>
);

/*--Privacy Policy Link--*/

type PrivacyPolicyLinkProps = {
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const privacyPolicyLinkWrapperStyles = css({
	marginTop: token('space.150', '12px'),
});

/**
 * Translated link to Atlassian's privacy policy.
 */
export const PrivacyPolicyLink = ({ onClick }: PrivacyPolicyLinkProps) => (
	<div css={privacyPolicyLinkWrapperStyles}>
		<Link href={'https://www.atlassian.com/legal/privacy-policy'} target="_blank" onClick={onClick}>
			<FormattedMessage
				id="post-office.ad-controls.reasons-modal.privacy-policy-link.text"
				defaultMessage="Learn more about your data privacy with Atlassian"
				description="The text linking to Atlassian's privacy policy. https://hello.atlassian.net/l/cp/ssoqm2Fq"
			/>
		</Link>
	</div>
);

/*--Reason Modal--*/

// Define full body content of modal
type FullBodyContent = {
	body: React.ReactNode;
	message?: never;
	reasons?: never;
	privacyPolicy?: never;
};

// Define partial body content of modal, use default for rest
type PartialBodyContent = {
	body?: never;
	message?: React.ReactNode;
	reasons?: React.ReactNode;
	privacyPolicy?: React.ReactNode;
};

type ModalContent = {
	title?: React.ReactNode;
} & (FullBodyContent | PartialBodyContent);

type WhyAmISeeingThisModalProps = {
	isOpen: boolean;
	onClose: () => void;
	additionalAnalyticsAttributes?: Record<string, string>;
	content?: ModalContent;
};

const ReasonsModalDefaultBody = ({
	onClose,
	content,
	additionalAnalyticsAttributes,
}: WhyAmISeeingThisModalProps) => {
	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();

	useEffect(() => {
		fireAnalyticsEvent(
			{
				eventType: 'screen',
				name: 'adControlsReasonsModal',
			},
			{
				...additionalAnalyticsAttributes,
			},
		);
	}, [fireAnalyticsEvent, additionalAnalyticsAttributes]);

	const handleOnPrivacyPolicy = () => {
		fireAnalyticsEvent({
			eventType: 'ui',
			action: 'clicked',
			actionSubject: 'link',
			actionSubjectId: 'adControlsPrivacyPolicy',
		});
	};
	return (
		<>
			<ModalHeader>
				<ModalTitle>{content?.title || <Title />}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{content?.body || (
					<>
						{content?.message || <DefaultBody />}
						{content?.reasons || <ReasonsList reasons={REASONS} />}
						{content?.privacyPolicy || (
							<PrivacyPolicyLink onClick={() => handleOnPrivacyPolicy()} />
						)}
					</>
				)}
			</ModalBody>
			<ModalFooter>
				<AcceptButton onClick={onClose} />
			</ModalFooter>
		</>
	);
};

export const WhyAmISeeingThisModal = (props: WhyAmISeeingThisModalProps) => {
	return (
		<Modal isOpen={props.isOpen}>
			<ReasonsModalDefaultBody {...props} />
		</Modal>
	);
};
