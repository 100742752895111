import { PostOfficeError, PostOfficeErrorCodes } from '@post-office/errors';

import { type PlacementLevel, type PlacementLocation, type RequestContext } from '../types';

export class PlacementSystemError extends PostOfficeError {
	placementId?: string;
	messageTemplateId?: string;
	stageName?: string;
	placementLevel?: PlacementLevel;

	constructor(
		params: {
			message?: string;
			error?: Error;
			code?: PostOfficeErrorCodes;
		} & PlacementLocation,
	) {
		const message = params.message ?? params.error?.message ?? 'unknown placement system error';
		const code = params.code ?? PostOfficeErrorCodes.PlacementSystemError;

		super(message, code);
		this.name = params.error?.name ?? this.constructor.name;
		this.messageTemplateId = params.messageTemplateId;
		this.placementId = params.placementId;
		this.placementLevel = params.placementLevel;
		this.stageName = params.stageName;
		this.cause = params.error?.cause;
		this.stack = params.error?.stack;
	}
}

type Params = ConstructorParameters<typeof PlacementSystemError>[0];

export class PlacementMessageError extends PlacementSystemError {
	constructor(params: Params) {
		super({
			...params,
			message: 'unknown placement message error',
			code: PostOfficeErrorCodes.PlacementMessageError,
		});
	}
}

export class PlacementError extends PlacementSystemError {
	constructor(params: Params) {
		super({
			...params,
			message: 'unknown placement error',
			code: PostOfficeErrorCodes.PlacementError,
		});
	}
}

export class PlacementRuntimeError extends PlacementSystemError {
	constructor(params: Params) {
		super({
			...params,
			message: 'unknown placement runtime error',
			code: PostOfficeErrorCodes.PlacementRuntimeError,
		});
	}
}

export class PlacementMiddlewareError extends PlacementSystemError {
	constructor(params: Params) {
		super({
			...params,
			message: 'unknown placement middleware error',
			code: PostOfficeErrorCodes.PlacementRuntimeError,
		});
	}
}

export const createPlacementErrorFactory =
	(errorType: typeof PlacementSystemError) =>
	(error: unknown): PlacementMessageError => {
		if (error instanceof errorType) {
			return new errorType({ ...error, error });
		}

		if (error instanceof Error) {
			return new errorType({ error });
		}

		return new errorType({});
	};

export const createPlacementMessageError = createPlacementErrorFactory(PlacementSystemError);

export const handlePlacementMessageError = (request: RequestContext) => (error: unknown) =>
	logPlacementError(request)(createPlacementMessageError(error));

export const createPlacementError = createPlacementErrorFactory(PlacementSystemError);

export const handlePlacementError = (request: RequestContext) => (error: unknown) =>
	logPlacementError(request)(createPlacementError(error));

export const createPlacementSystemError = createPlacementErrorFactory(PlacementSystemError);

export const handlePlacementSystemError = (request: RequestContext) => (error: unknown) =>
	logPlacementError(request)(createPlacementSystemError(error));

export const createPlacementRuntimeError = createPlacementErrorFactory(PlacementRuntimeError);

export const handlePlacementRuntimeError = (request: RequestContext) => (error: unknown) =>
	logPlacementError(request)(createPlacementRuntimeError(error));

export const createPlacementMiddlewareError = createPlacementErrorFactory(PlacementMiddlewareError);

export const handlePlacementMiddlewareError = (request: RequestContext) => (error: unknown) =>
	logPlacementError(request)(createPlacementMiddlewareError(error));

export const logPlacementError = (request: RequestContext) => (error: PlacementSystemError) => {
	const location = request?.location?.getCurrent();

	request.logger.error({
		...location,
		message: error.message,
		cause: error.cause,
		name: error.name,
		stack: error.stack,
		level: error.placementLevel,
	});

	return error;
};
