import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import type { NextBestAction } from '@post-office/confluence-next-best-action';
import {
	type BackendMessageBody,
	type DefaultMessagesResponse,
	createPlacement,
} from '@post-office/placement-common-v2';
import type { SerializableRecord } from '@post-office/serializable-record';
import { usePlacementApi } from '@post-office/use-placement-api';
import { useCallback, useEffect, useState } from 'react';

const { createFrontend, createBackend } = createPlacement({
	placementId: 'confluence-side-nav',
	options: {
		choreographer: {
			isDefaultEnabled: false,
		},
	},
});

export const PlacementBackend = createBackend({
	params: {
		dataRetrivalType: 'implicit-only',
	},
});

// Props passed from Product to Placement
type PlacementFrontendProps = {
	preloadedInitialData: DefaultMessagesResponse | undefined;
	onSideNavClick: (nextBestActionType: NextBestAction) => void;
};

const confluenceSideNavStyles = css({
	display: 'flex',
	margin: token('space.150', '12px'),
});

export const PlacementFrontend = createFrontend(
	(Messages) =>
		({ preloadedInitialData, onSideNavClick }: PlacementFrontendProps) => {
			const [{ messages, status }, setMessageState] = useState<{
				messages: BackendMessageBody<SerializableRecord>[] | undefined;
				status: 'loading' | 'done';
			}>(() => {
				if (preloadedInitialData) {
					return preloadedInitialData?.messages?.length > 0
						? {
								messages: preloadedInitialData?.messages,
								status: 'done',
							}
						: { messages: undefined, status: 'done' };
				}

				return { messages: undefined, status: 'loading' };
			});

			const isDone = status === 'done';

			const { data, error, isLoading } = usePlacementApi<DefaultMessagesResponse>('/', {
				autoRefetch: {
					isEnabled: !isDone,
					updateKey: 'confluence-side-nav',
				},
			});

			const handleOnDismissed = useCallback(() => {
				// no more messages until the next fetch
				setMessageState({ messages: undefined, status: 'done' });
			}, []);

			const handleOnSideNavClick = useCallback(
				(nextBestActionType: NextBestAction) => {
					onSideNavClick(nextBestActionType);

					// display the next message
					setMessageState((prevState) => {
						if (prevState.messages) {
							return {
								messages: prevState.messages.slice(1),
								status: 'done',
							};
						}

						return prevState;
					});
				},
				[onSideNavClick],
			);

			useEffect(() => {
				if (!isLoading && !isDone) {
					setMessageState({
						messages: data?.messages,
						status: 'done',
					});
				}
			}, [isLoading, isDone, data]);

			if (error) {
				return null;
			}

			if (status === 'loading') {
				return null;
			}

			return Array.isArray(messages) && messages.length > 0 ? (
				<div css={confluenceSideNavStyles}>
					<Messages.render
						{...messages[0]}
						onSideNavClick={handleOnSideNavClick}
						handleOnDismissed={handleOnDismissed}
					/>
				</div>
			) : null;
		},
);
