import { type NextBestAction } from '@post-office/confluence-next-best-action';
import {
	PlacementBackend,
	PlacementFrontend,
} from '@post-office/placements--growth/confluence-side-nav';

import { ConfluenceCreateASpaceMessage } from './messages/confluence-create-a-space';
import { ConfluenceCreateFirstPage } from './messages/confluence-create-first-page';
import { ConfluenceCreateFirstWhiteboard } from './messages/confluence-create-first-whiteboard';
import { type MessageTemplateContext } from '../../..';
import { assertMessageTemplateContext } from '../../../__generated__/validation/messageTemplateContext';

export const MessageBackend = PlacementBackend.createMessageBackend(() => ({
	transformMetadata: {
		validate: assertMessageTemplateContext,
		stage: ({ message }) => ({
			goalMetadata: {
				primaryGoal: {
					goalId: 'clickthrough',
				},
			},
			nextBestAction: message.context.nextBestActionType,
		}),
	},
}));

type MessageFrontendProps = MessageTemplateContext & {
	onSideNavClick: (nextBestActionType: NextBestAction) => string | void | null;
	handleOnDismissed: () => void;
};

export const MessageFrontend = PlacementFrontend.createMessageFrontend({
	render: {
		validate: assertMessageTemplateContext,
		component: ({
			nextBestActionType,
			onSideNavClick,
			handleOnDismissed,
		}: MessageFrontendProps) => {
			switch (nextBestActionType) {
				case 'CREATE_SPACE':
					return (
						<ConfluenceCreateASpaceMessage
							onSideNavClick={onSideNavClick}
							handleOnDismissed={handleOnDismissed}
						/>
					);
				case 'CREATE_PAGE':
					return (
						<ConfluenceCreateFirstPage
							onSideNavClick={onSideNavClick}
							handleOnDismissed={handleOnDismissed}
						/>
					);
				case 'CREATE_WHITEBOARD':
					return (
						<ConfluenceCreateFirstWhiteboard
							onSideNavClick={onSideNavClick}
							handleOnDismissed={handleOnDismissed}
						/>
					);
				default:
					return <></>;
			}
		},
	},
});
