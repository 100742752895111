import { MessageBackend as createMessageBackendConfluenceCreateASpace, MessageFrontend as createMessageFrontendConfluenceCreateASpace } from '@post-office/message-template--confluence-create-a-space/placements/in-app-v2/confluence-side-nav'
import { MessageBackend as createMessageBackendConfluenceSideNavCreateASpace, MessageFrontend as createMessageFrontendConfluenceSideNavCreateASpace } from '@post-office/message-template--confluence-side-nav-create-a-space/placements/in-app-v2/confluence-side-nav'
import { productionRuntime } from '@post-office/placement-common-v2/placement-runtime';
import { PlacementBackend, PlacementFrontend } from '@post-office/placements--growth/confluence-side-nav';


const messageBackends = [
createMessageBackendConfluenceSideNavCreateASpace({messageTemplateId: 'confluence-side-nav-create-a-space'}),
createMessageBackendConfluenceCreateASpace({messageTemplateId: 'confluence-create-a-space'}),
]

const messageFrontends = [
createMessageFrontendConfluenceSideNavCreateASpace({messageTemplateId: 'confluence-side-nav-create-a-space'}),
createMessageFrontendConfluenceCreateASpace({messageTemplateId: 'confluence-create-a-space'}),
]

export const placementBackendFactory = PlacementBackend.createPlacementBackend({
				messageTemplates: messageBackends,
			})

export const Placement = PlacementFrontend.createPlacementFrontendComponent({
				messageTemplates: messageFrontends,
			})

export const middleware = placementBackendFactory(productionRuntime);