import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { type CustomTriggerProps } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { type Size } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { useMessageContext } from '@post-office/message-context';
import { useMessageInstanceEvent } from '@post-office/message-instance-event-frontend-client';
import { useMessageEvent } from '@post-office/message-lifecycle';
import { type ComponentProps, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { AdControlsFlag } from './components/dismissalFlag';
import { AdControlsDropdown } from './components/dropdownMenu';
import { PrivacyPolicyLink, WhyAmISeeingThisModal } from './components/modal';

export { PrivacyPolicyLink };

type DropdownTriggerProps = CustomTriggerProps<HTMLButtonElement> & {
	name: string;
	iconSize?: Size;
	iconColor?: string;
};

const DropdownTrigger = ({
	triggerRef,
	name,
	iconSize = 'small',
	iconColor,
	...triggerProps
}: DropdownTriggerProps) => {
	const intl = useIntl();
	const ariaLabel = intl.formatMessage(
		{
			id: 'post-office.ad-controls.dropdown-menu-trigger.aria-label',
			defaultMessage: '{messageInstanceId} options menu',
			description: 'Options menu ad controls',
		},
		{ messageInstanceId: name },
	);
	const tooltip = intl.formatMessage({
		id: 'post-office.ad-controls.dropdown-menu-trigger.tooltip',
		defaultMessage: 'More actions',
		description: 'Tooltip for ad-controls dropdown',
	});

	return (
		<Tooltip content={tooltip}>
			<IconButton
				{...triggerProps}
				label={ariaLabel}
				appearance="subtle"
				ref={triggerRef}
				icon={(iconProps) => <MoreIcon {...iconProps} primaryColor={iconColor} size={iconSize} />}
			/>
		</Tooltip>
	);
};

/*--ad controls--*/

export type Props = {
	onDismissed?: (messageInstanceId: string) => void;
	iconSize?: Size;
	iconColor?: string;
	additionalAnalyticsAttributes?: Record<string, string>;
	/**
	 * This is a prop that is used to pass the content to the WhyAmISeeingThisModal.
	 * The content is used to provide the user with a reason why they are seeing the message.
	 * If not sent, generic reasons will be provided.
	 */
	whyAmISeeingThisModalContent?: ComponentProps<typeof WhyAmISeeingThisModal>['content'];
};

/**
 * AdControls component is used to render the dropdown menu for the ad controls.
 * This should be displayed next to each message and allows users to dismiss the message or see why they are seeing it.
 */
export const AdControls = ({
	onDismissed,
	iconSize,
	iconColor,
	whyAmISeeingThisModalContent,
	additionalAnalyticsAttributes = {},
}: Props) => {
	const [isWhyAmISeeingThisModelOpened, setIsWhyAmISeeingThisModelOpened] = useState(false);
	const { messageDismissed, messageAcknowledged } = useMessageEvent();
	const { messageInstanceClicked } = useMessageInstanceEvent();
	const { messageInstanceId } = useMessageContext();
	const [currIconColor, setCurrIconColor] = useState(iconColor);

	if (!messageInstanceId) {
		throw new Error('messageInstanceId is required');
	}

	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();
	const [showDismissedFlag, setShowDismissedFlag] = useState(false);

	const handleNotInterested = async () => {
		await messageDismissed();
		await messageInstanceClicked({
			messageEventType: 'clicked',
			clickedElement: {
				clickIdentifier: 'notInterested',
				clickedElement: 'button',
				buttonType: 'primary',
			},
			action: {
				actionType: 'dismiss',
			},
			additionalMetadataFromFrontend: {},
		});
		fireAnalyticsEvent(
			{
				eventType: 'ui',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'notInterested',
			},
			{ ...additionalAnalyticsAttributes },
		);

		if (messageInstanceId && typeof onDismissed === 'function') {
			// integrating with useMessagePlacementHooks removes the need to pass this callback
			onDismissed(messageInstanceId);
		}
		setShowDismissedFlag(true);
	};

	const handleWhyAmISeeingThis = async () => {
		setIsWhyAmISeeingThisModelOpened(true);
		await messageAcknowledged();
		await messageInstanceClicked({
			messageEventType: 'clicked',
			clickedElement: {
				clickIdentifier: 'adControlsReasons',
				clickedElement: 'button',
				buttonType: 'primary',
			},
			action: {
				actionType: 'acknowledge',
			},
			additionalMetadataFromFrontend: {},
		});
		fireAnalyticsEvent(
			{
				eventType: 'ui',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'adControlsReasons',
			},
			{ ...additionalAnalyticsAttributes },
		);
	};

	return (
		<>
			<DropdownMenu
				testId="post-office-ad-controls-dropdown"
				trigger={({ triggerRef, ...triggerProps }) => (
					<DropdownTrigger
						name={messageInstanceId}
						triggerRef={triggerRef}
						iconSize={iconSize}
						iconColor={currIconColor}
						{...triggerProps}
					/>
				)}
				onOpenChange={(args) => {
					if (args.isOpen) {
						fireAnalyticsEvent({
							eventType: 'ui',
							action: 'clicked',
							actionSubject: 'button',
							actionSubjectId: 'adControlsDropdown',
						});
						setCurrIconColor(token('color.icon.selected'));
					} else {
						setCurrIconColor(iconColor);
					}
				}}
			>
				<AdControlsDropdown
					onNotInterested={handleNotInterested}
					onWhyAmISeeingThis={handleWhyAmISeeingThis}
				/>
			</DropdownMenu>
			<WhyAmISeeingThisModal
				content={whyAmISeeingThisModalContent}
				isOpen={isWhyAmISeeingThisModelOpened}
				additionalAnalyticsAttributes={additionalAnalyticsAttributes}
				onClose={() => {
					setIsWhyAmISeeingThisModelOpened(false);
					fireAnalyticsEvent(
						{
							eventType: 'ui',
							action: 'clicked',
							actionSubject: 'button',
							actionSubjectId: 'adControlsReasonsClose',
						},
						{ ...additionalAnalyticsAttributes },
					);
				}}
			/>

			{showDismissedFlag && (
				<AdControlsFlag
					messageInstanceId={messageInstanceId}
					onCloseFlag={() => setShowDismissedFlag(false)}
				/>
			)}
		</>
	);
};
