import type { TransformMetadataResult } from '@post-office/shared-contracts';

import { type TransformMetadataBackendPlacementStage } from './types';
import { Stages } from '..';
import type { AnyBackendPlacementStageInput } from '../../../../create-placement/types';

export const applyTransformMetadata =
	(transformMetadata?: TransformMetadataBackendPlacementStage) =>
	async (params: AnyBackendPlacementStageInput) => {
		const transformedMetadata = await Stages.maybe(transformMetadata)(params);

		return Stages.mergeMetadata<TransformMetadataResult>((original, metadata) => ({
			...original,
			enrichedAttributes: metadata,
		}))(params, transformedMetadata);
	};
