import React from 'react';
import { PlacementFetchingProvider } from '@atlassian/post-office-placement-data';
import { usePlacementSSRFetch } from './preload-placement';

export const PlacementFetchProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const fetch = usePlacementSSRFetch();

	return <PlacementFetchingProvider fetch={fetch}>{children}</PlacementFetchingProvider>;
};
