import { useMessageInstanceEvent } from '@post-office/message-instance-event-frontend-client';
import { useMessageEvent } from '@post-office/message-lifecycle';
import { type ClickedElementAttributes } from '@post-office/shared-contracts';
import { useCallback } from 'react';

export const useActionMessageClicked = (
	onMessageClick: (e: React.MouseEvent<HTMLElement>) => void,
	clickedElement: ClickedElementAttributes,
) => {
	const { messageClicked, messageDismissed } = useMessageEvent();
	const { messageInstanceClicked } = useMessageInstanceEvent();

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			messageClicked().catch(() => {});
			messageInstanceClicked({
				messageEventType: 'clicked',
				clickedElement,
				action: {
					actionType: 'acknowledge',
				},
				additionalMetadataFromFrontend: {},
			}).catch(() => {});
			onMessageClick(e);

			// Temporary workaround to dismiss the message after the user clicks on a Create Whiteboard action
			// TODO: Remove this workaround once CREATE_WHITEBOARD_REALTIME trait is fixed.
			// https://hello.jira.atlassian.cloud/browse/PO-4167
			if (clickedElement.clickIdentifier.includes('CREATE_WHITEBOARD')) {
				messageDismissed().catch(() => {});
			}
		},
		[clickedElement, messageClicked, messageInstanceClicked, onMessageClick],
	);

	return { handleClick };
};
