import { DropdownItem, DropdownItemGroup, type DropdownItemProps } from '@atlaskit/dropdown-menu';
import { FormattedMessage } from 'react-intl-next';

/*--Why am I seeing this--*/

type WhyAmISeeingThisProps = Omit<DropdownItemProps, 'children'>;

export const WhyAmISeeingThis = (props: WhyAmISeeingThisProps) => (
	<DropdownItem {...props}>
		<FormattedMessage
			id="post-office.ad-controls.dropdown-item.why-am-i-seeing-this.label"
			defaultMessage="Why am I seeing this?"
			description="The text for the button which opens a modal showing the reasons a message has been displayed."
		/>
	</DropdownItem>
);

/*--Not interested--*/

type NotInterestedProps = Omit<DropdownItemProps, 'children'>;

export const NotInterested = (props: NotInterestedProps) => (
	<DropdownItem {...props}>
		<FormattedMessage
			id="post-office.ad-controls.dropdown-item.not-interested.label"
			defaultMessage="Not interested"
			description="The text for the button which removes a message from a placement."
		/>
	</DropdownItem>
);

type AddControlsDropdownProps = {
	onWhyAmISeeingThis: (
		e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
	) => void;
	onNotInterested: (
		e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
	) => void;
};

export const AdControlsDropdown = ({
	onWhyAmISeeingThis,
	onNotInterested,
}: AddControlsDropdownProps) => (
	<DropdownItemGroup>
		<NotInterested testId="ad-controls-dropdown-not-interested" onClick={onNotInterested} />
		<WhyAmISeeingThis
			testId="ad-controls-dropdown-why-am-I-seeing-this"
			onClick={onWhyAmISeeingThis}
		/>
	</DropdownItemGroup>
);
