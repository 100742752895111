import { type ArityOneParameter } from '@post-office/shared-contracts/pipeline/contraints';

import type {
	AnyBackendMessageBody,
	AnyBackendPlacementStage,
	AnyBackendPlacementStageData,
	AnyBackendPlacementStageInput,
	AnyPayload,
	BackendPlacementStageData,
} from '../../../create-placement/types';

const maybe =
	<T extends AnyBackendPlacementStage>(stage: T | undefined) =>
	async (params: ArityOneParameter<T>): Promise<Awaited<ReturnType<T>>> => {
		return (stage?.(params) ?? params.data) as Awaited<ReturnType<T>>;
	};

const mergeMetadata = <T extends AnyPayload>(
	fn: (target: AnyBackendMessageBody, source: T) => AnyBackendMessageBody,
) => {
	const applyMerge = (
		target: AnyBackendPlacementStageInput,
		source: BackendPlacementStageData<T>,
	): AnyBackendPlacementStageData => {
		{
			const sourceMap = Object.fromEntries(
				source.messages.map((message) => [message.messageInstanceId, message]),
			);

			const messages = target.data.messages.map((message) =>
				fn(message, sourceMap?.[message.messageInstanceId]?.context as T),
			);

			return { messages };
		}
	};

	return applyMerge;
};

export const Stages = {
	/**
	 * The `maybe` function creates a passthrough stage,
	 * which applies no changes, for cases where a stage might be undefined.
	 */
	maybe,
	/**
	 * the `mergeMetadata` function is used when the result of a stage is
	 * used to modify the message payload outside the message context
	 */
	mergeMetadata,
};
