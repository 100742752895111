// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { type VMessageTemplateContext } from '../../index.ts';

import { AssertError } from '@post-office/validation-types';
export function assert(value: any): VMessageTemplateContext {
    const result = validate(value);
    if (!result.success) {
        const firstError = result.errors[0];
        throw new AssertError('assert', firstError.path, firstError.expected, firstError.value);
    }
    return result.data as VMessageTemplateContext;
}
export { assert as assertMessageTemplateContext };


export function is(value: any): value is VMessageTemplateContext {
	const result = validate(value);
    return result.success;
}
export { is as isMessageTemplateContext };


import { type ValidationResult } from '@post-office/validation-types';
export function validate(value: any): ValidationResult<VMessageTemplateContext> {
    const errors = [];
    const originalData = value;
    
	if (typeof value !== 'object' || value === null || Array.isArray(value)) {
        errors.push({ path: 'value', expected: 'MessageTemplateContext', value: value });
    } else {
			if (!('testProp' in value)) {
				errors.push({ path: 'value["testProp"]', expected: 'string', value: undefined });
			} else {
				if (typeof value["testProp"] !== 'string') errors.push({ path: 'value["testProp"]', expected: 'string', value: value["testProp"] });
			}
			if (!('testObjProp' in value)) {
				errors.push({ path: 'value["testObjProp"]', expected: 'object', value: undefined });
			} else {
				
	if (typeof value["testObjProp"] !== 'object' || value["testObjProp"] === null || Array.isArray(value["testObjProp"])) {
        errors.push({ path: 'value["testObjProp"]', expected: 'object', value: value["testObjProp"] });
    } else {
			if (!('testObjPropKey' in value["testObjProp"])) {
				errors.push({ path: 'value["testObjProp"]["testObjPropKey"]', expected: 'string', value: undefined });
			} else {
				if (typeof value["testObjProp"]["testObjPropKey"] !== 'string') errors.push({ path: 'value["testObjProp"]["testObjPropKey"]', expected: 'string', value: value["testObjProp"]["testObjPropKey"] });
			}
    }
			}
			if (!('testArrayProp' in value)) {
				errors.push({ path: 'value["testArrayProp"]', expected: 'number[]', value: undefined });
			} else {
				if (!Array.isArray(value["testArrayProp"])) errors.push({ path: 'value["testArrayProp"]', expected: 'array', value: value["testArrayProp"] });if (Array.isArray(value["testArrayProp"])) { value["testArrayProp"].forEach((element, index) => { if (typeof element !== 'number') errors.push({ path: 'element', expected: 'number', value: element }); }); }
			}
    }
    if (errors.length === 0) return { success: true, data: value };
    return { success: false, data: originalData, errors: errors };
}
export { validate as validateMessageTemplateContext };
