import type { PlacementBackendRuntimeResult, PlacementMiddleware } from '../types';
import { flattenMessagesForFrontend } from '../util/flatten-messages-for-frontend';

export const defaultPlacementMiddleware: PlacementMiddleware =
	() =>
	async ({ request, koa, postOffice }) => {
		try {
			const result = await postOffice.messagesRuntime({ request });

			heuristicForPostOfficeFailure(result);

			koa.ctx.body = flattenMessagesForFrontend(result.data);

			koa.ctx.status = 200;
		} catch (error) {
			request.logger.error({ error }, 'placement middleware error');

			koa.ctx.status = 500;

			koa.ctx.body = {
				message: 'An error occurred',
			};
		}
	};

const heuristicForPostOfficeFailure = ({ errors, data }: PlacementBackendRuntimeResult): void => {
	const { messages } = data;

	if (!(messages.length > 1)) return;

	const uniqueErrorIds = new Set(errors.map((e) => e?.messageTemplateId ?? 'unknown'));
	const uniqueMessageIds = new Set(messages.map((e) => e.messageTemplateId));

	if (uniqueErrorIds.size > 1 && uniqueMessageIds.size / uniqueMessageIds.size > 0.1) {
		// Better error required
		throw new Error('too many message template errors');
	}
};
