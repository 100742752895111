import { type BackendMessageStageInput } from '@post-office/placement-common-v2';
import {
	PlacementBackend,
	PlacementFrontend,
} from '@post-office/placements--growth/confluence-side-nav';

import { assertMessageTemplateContext } from '../../../__generated__/validation/messageTemplateContext';
import { type MessageTemplateContext } from '../../../index';

export const MessageBackend = PlacementBackend.createMessageBackend(() => ({
	transformMetadata: {
		validate: assertMessageTemplateContext,
		stage: () => ({
			goalMetadata: {
				primaryGoal: {
					goalId: 'clickthrough',
				},
			},
		}),
	},
	hydrate: {
		validate: assertMessageTemplateContext,
		stage: ({
			message,
		}: BackendMessageStageInput<MessageTemplateContext>): Promise<MessageTemplateContext> => {
			message.context.testProp = `Hello ${message.context.testProp} !`;
			return Promise.resolve(message.context);
		},
	},
}));

export const MessageFrontend = PlacementFrontend.createMessageFrontend({
	render: {
		validate: assertMessageTemplateContext,
		component: () => <></>,
	},
});
