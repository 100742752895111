/**
 * The Marker type that represents a type that can be used to generate validation code.
 *
 * Wrapping a type with this type will direct the code generator to generate validation code for it.
 */
export type GenerateValidationFor<T> = T;

export type ValidationResult<T> = ValidationResultSuccess<T> | ValidationResultFailure;

export type ValidationResultSuccess<T> = {
	success: true;
	data: T;
};

export type ValidationResultFailure = {
	success: false;
	data: unknown;
	errors: ValidationError[];
};

export type ValidationError = {
	path: string;
	expected: string;
	value: unknown;
};

export class AssertError extends Error {
	constructor(
		public readonly method: string,
		public readonly path: string | undefined,
		public readonly expected: string,
		public readonly value: unknown,
	) {
		const message = path
			? `Error on ${method}(): invalid type on ${path}, expect to be ${expected}`
			: `Error on ${method}(): invalid type, expect to be ${expected}`;

		super(message);
	}
}
