import { type NextBestAction } from '@post-office/confluence-next-best-action';
import { ConfluenceNbaBannerMessage } from '@post-office/placements--growth/components/confluence-side-nav';
import { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

const NBAIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M25.4297 21.0963C25.4963 20.7255 25.5297 20.3255 25.5297 19.9088C25.5297 19.4922 25.4963 19.0838 25.4255 18.7047C25.0213 16.4588 23.463 15.0755 21.2713 14.7172C20.8963 14.6547 20.5047 14.6255 20.1005 14.6255C19.6963 14.6255 19.2672 14.6588 18.8797 14.7255C16.6922 15.0922 15.1338 16.4713 14.7297 18.7047C14.663 19.0838 14.6255 19.4838 14.6255 19.9088C14.6255 20.3338 14.6588 20.7255 14.7255 21.0963C15.1255 23.3255 16.6838 24.7088 18.8797 25.0755C19.2672 25.1422 19.6755 25.1755 20.1005 25.1755C20.5255 25.1755 20.9005 25.1463 21.2713 25.0838C23.463 24.7255 25.0255 23.3422 25.4255 21.0963H25.4297Z"
			fill="#FCA700"
		/>
		<path d="M7.37109 19.317L13.8711 8.17529L20.3711 19.317H7.37109Z" fill="#BF63F3" />
		<path
			d="M17.846 14.9922C16.2043 15.5672 15.0627 16.838 14.7293 18.7047C14.6918 18.9047 14.6668 19.1047 14.646 19.3172H20.3668L17.846 14.9922Z"
			fill="#AE2A19"
		/>
		<path d="M29.917 20.8838H20.7837V30.0171H29.917V20.8838Z" fill="#82B536" />
		<path
			d="M20.7837 20.8877V25.146C20.9504 25.1294 21.1129 25.1085 21.2754 25.0835C23.467 24.7294 25.0254 23.3419 25.4295 21.096C25.442 21.0294 25.4545 20.9585 25.4629 20.8877H20.7837Z"
			fill="#AE2A19"
		/>
	</svg>
);

export const ConfluenceCreateFirstWhiteboard = ({
	onSideNavClick,
	handleOnDismissed,
}: {
	onSideNavClick: (nextBestActionType: NextBestAction) => void;
	handleOnDismissed: () => void;
}) => {
	const intl = useIntl();
	const headerText = intl.formatMessage({
		id: 'post-office.confluence-side-nav.next-best-action.create-whiteboard-header',
		defaultMessage: 'Dream up new ideas, and visualize your work.',
		description: 'Header text for confluence side nav next best action banner',
	});

	const ctaMessage = intl.formatMessage({
		id: 'post-office.confluence-side-nav.next-best-action.create-whiteboard-cta-message',
		defaultMessage: 'Create whiteboard',
		description: 'Call to action text for confluence side nav next best action banner',
	});

	const onMessageClick = useCallback(() => {
		onSideNavClick('CREATE_WHITEBOARD');
	}, [onSideNavClick]);

	return (
		<ConfluenceNbaBannerMessage
			icon={<NBAIcon />}
			header={headerText}
			ctaMessage={ctaMessage}
			onMessageClick={onMessageClick}
			handleOnDismissed={handleOnDismissed}
			nextBestActionType="CREATE_WHITEBOARD"
		/>
	);
};
