import { defaultTransformMetadata } from '../../../stages/placement-message/backend/transform-metadata/default';
import { type AnyPayload } from '../../types';

export const defaultMessageBackend = () => ({
	transformMetadata: {
		validate: noValidation(),
		stage: defaultTransformMetadata,
	},
});

export const noValidation =
	<T = AnyPayload>(_valueWithType?: T) =>
	(input: unknown) =>
		input as T;
